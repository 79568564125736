import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
/* eslint-disable react-hooks/exhaustive-deps */
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';

import { unauthorizedMessage } from '../../services/AwCrudService.js';
import { getLoggedApps, getLogItems } from '../../services/LogItemService';
import Filter from '../Filter';
import LogItemList from '../LogItemList';

let tailInterval = null;

const MainContainerBase = ({ onUnauthorized }) => {
	const [apps, setApps] = useState([]);
	const [filter, setFilter] = useState({ appName: [], limit: 25, logLevel: 0, message: '', messageMatch: 0 });
	const [logItemCount, setLogItemCount] = useState(0);
	const [logItems, setLogItems] = useState([]);
	const { user, logout } = useKindeAuth();

	useEffect(() => {
		getLoggedApps()
			.then((loggedApps) => {
				let newFilter = cloneDeep(filter);
				newFilter.appName = newFilter.appName.concat(loggedApps);
				setApps(loggedApps || []);
				setFilter(newFilter);
			})
			.catch((e) => {
				if (e === unauthorizedMessage) {
					onUnauthorized();
				}
			});
	}, []);

	useEffect(() => {
		getLogs();
	}, [filter]);

	const cancelTail = () => {
		clearInterval(tailInterval);
		tailInterval = null;
		getLogs();
	};

	const getLogs = () => {
		getLogItems(filter)
			.then((logItemsResult) => {
				setLogItemCount(logItemsResult.recordCount);
				setLogItems(logItemsResult.logItems);
			})
			.catch((e) => {
				if (e === unauthorizedMessage) {
					onUnauthorized();
				}
			});
	};

	const handleFilterChange = (key, value) => {
		let newFilter = cloneDeep(filter);
		newFilter[key] = value;
		setFilter(newFilter);
	};

	const handleToggleAppName = (event) => {
		let app = event.target.value;
		let newFilter = cloneDeep(filter);
		let appIndex = newFilter.appName.indexOf(app);
		if (appIndex > -1) {
			newFilter.appName.splice(appIndex, 1);
		} else {
			newFilter.appName.push(app);
		}
		setFilter(newFilter);
	};

	const handleToggleMessageMatch = () => {
		let newFilter = cloneDeep(filter);
		newFilter.messageMatch = newFilter.messageMatch === 0 ? 1 : 0;
		setFilter(newFilter);
	};

	const handleToggleTail = () => {
		if (tailInterval === null) {
			tailInterval = setInterval(getLogs, 1000);
			setTimeout(cancelTail, 15 * 60 * 1000);
		} else {
			cancelTail();
		}
	};

	return (
		<main>
			<div className="container-fluid">
				<div>
					Hi {user?.given_name}, <button onClick={logout}>Log Out</button>{' '}
				</div>
				<hr />
				<Filter
					filter={filter}
					apps={apps}
					logItemCount={logItemCount}
					onMessageChange={(event) => handleFilterChange('message', event.target.value)}
					onToggleAppName={handleToggleAppName}
					onToggleMessageMatch={handleToggleMessageMatch}
					onLogLevelChange={(event) => handleFilterChange('logLevel', event.target.value)}
					onLimitChange={(event) => handleFilterChange('limit', event.target.value)}
					onToggleTail={handleToggleTail}
					tail={tailInterval !== null}
				/>
				<hr />
				<LogItemList logItems={logItems} />
			</div>
		</main>
	);
};

export default MainContainerBase;

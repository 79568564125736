import React from 'react';
import PropTypes from 'prop-types';
import ExpandableList from '../ExpandableList';
import { logLevels } from '../../Globals';

const LogItemListComponent = ({ logItems }) => {
	const getAlertLevel = (logLevel) => {
		if (logLevel >= logLevels.error) {
			return 'danger';
		}

		if (logLevel >= logLevels.warning) {
			return 'warning';
		}

		return 'secondary';
	};

	return (
		<div>
			{logItems.map((logItem) => {
				let alertLevel = getAlertLevel(logItem.logLevel);
				return (
					<div className={`row alert alert-${alertLevel}`} key={logItem._id}>
						<div className='col col-2'>{new Date(logItem.logDate).toLocaleString()}</div>
						<div className='col col-2'>{logItem.appName}</div>
						<div className='col col-8'>{logItem.message}</div>
						<div className='col col-6'>
							<ExpandableList list={[logItem.data]} listName='Data' />
						</div>
						<div className='col col-6'>
							<ExpandableList list={logItem.stackTrace} listName='Stack Trace' />
						</div>
					</div>
				);
			})}
		</div>
	);
};

LogItemListComponent.propTypes = {
	logItems: PropTypes.array.isRequired
};

export default LogItemListComponent;

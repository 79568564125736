import React from 'react';
import PropTypes from 'prop-types';

const MESSAGE_MATCH_TEXT = ['is like', 'equals'];

const FilterComponent = ({
	apps,
	filter,
	onToggleAppName,
	onLogLevelChange,
	onToggleMessageMatch,
	onMessageChange,
	tail,
	onToggleTail,
	onLimitChange,
	logItemCount
}) => {
	return (
		<div className='row'>
			<div className='col'>
				<div>
					{'Apps: '}
					{apps.map((app) => (
						<span key={app}>
							<label htmlFor={app}>{app}</label>{' '}
							<input type='checkbox' id={app} value={app} checked={filter.appName.indexOf(app) > -1} onChange={onToggleAppName} />{' '}
						</span>
					))}
				</div>
				<div>
					{'Log level: '}
					<select value={filter.logLevel} onChange={onLogLevelChange}>
						<option value={0}>Info</option>
						<option value={1}>Warning</option>
						<option value={2}>Error</option>
					</select>
				</div>
			</div>
			<div className='col'>
				<div className='input-group'>
					<div className='input-group-prepend'>
						<span className='input-group-text' onClick={onToggleMessageMatch}>{`Message ${
							MESSAGE_MATCH_TEXT[filter.messageMatch]
						}`}</span>
					</div>
					<input type='text' className='form-control' value={filter.message} onChange={onMessageChange} />
				</div>
			</div>
			<div className='col'>
				<label htmlFor='tail'>Tail:</label> <input type='checkbox' id='tail' value='tail' checked={tail} onChange={onToggleTail} />
				<br />
				{'Showing latest '}
				<select value={filter.limit} onChange={onLimitChange}>
					<option value={25}>25</option>
					<option value={50}>50</option>
					<option value={100}>100</option>
				</select>
				{` of ${logItemCount}`}
			</div>
		</div>
	);
};

FilterComponent.propTypes = {
	apps: PropTypes.array.isRequired,
	filter: PropTypes.object.isRequired,
	logItemCount: PropTypes.number.isRequired,
	onLimitChange: PropTypes.func.isRequired,
	onLogLevelChange: PropTypes.func.isRequired,
	onMessageChange: PropTypes.func.isRequired,
	onToggleAppName: PropTypes.func.isRequired,
	onToggleMessageMatch: PropTypes.func.isRequired,
	onToggleTail: PropTypes.func.isRequired,
	tail: PropTypes.bool.isRequired
};

export default FilterComponent;

import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useEffect, useState } from 'react';

import { orgCode } from '../../Globals.js';
import { setAuth } from '../../services/AwCrudService.js';

const withLogin = (WrappedComponent) => {
	const HOC = (props) => {
		const [receivedUnauthorizedResponse, setReceivedUnauthorizedResponse] = useState(false);
		const { isAuthenticated, isLoading, login, logout, getToken } = useKindeAuth();
		const [isStillLoading, setIsStillLoading] = useState(true);

		const handleUnauthorized = () => {
			setReceivedUnauthorizedResponse(true);
		};

		useEffect(() => {
			if (isAuthenticated) {
				getToken().then((accessToken) => {
					setAuth(accessToken);
					setIsStillLoading(false);
				});
			} else {
				setIsStillLoading(true);
			}
		}, [isAuthenticated, getToken]);

		if (receivedUnauthorizedResponse) {
			return (
				<div>
					It appears that you shouldn't be here. If you think that's wrong, <button onClick={logout}>Log out</button> and try again.
				</div>
			);
		}

		if (!isAuthenticated) {
			return (
				<div>
					To get started, <button onClick={() => login({ org_code: orgCode })}>Log in</button>
				</div>
			);
		}

		if (isLoading || isStillLoading) {
			return <p>Loading</p>;
		}

		return <WrappedComponent {...props} onUnauthorized={handleUnauthorized} />;
	};
	HOC.displayName = `withLogin(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

	return HOC;
};

export default withLogin;

import { isNullOrUndefined } from '@chris-crap-packages/utilities';
import { StatusCodes } from 'http-status-codes';

import { apiUri, paulBunyan } from '../Globals';

let _authHeader = '';

export const unauthorizedMessage = 'User is unauthorized';

export const setAuth = (token) => {
	_authHeader = `Bearer ${token}`;
};

export function createOnServer() {
	// let fullUri = BASE_URI + uri;
	// return new Promise((resolve, reject) => {
	// 	talkToServer(REQUEST_TYPE.POST, fullUri, resolve, reject, data);
	// });
	paulBunyan.logWarning('createOnServer not implemented yet', undefined, true);
}

export function deleteFromServer() {
	// let fullUri = BASE_URI + uri;
	// return new Promise((resolve, reject) => {
	// 	talkToServer(REQUEST_TYPE.DELETE, fullUri, resolve, reject);
	// });
	paulBunyan.logWarning('deleteFromServer not implemented yet', undefined, true);
}

export function retrieveFromServer(uri) {
	return new Promise((resolve, reject) => {
		const fullUri = `${apiUri}/${uri}`;
		const fetchinOptions = {
			headers: {
				Authorization: _authHeader,
			},
		};
		fetch(fullUri, fetchinOptions)
			.then((response) => {
				if (response.status === StatusCodes.UNAUTHORIZED || response.status === StatusCodes.FORBIDDEN) {
					reject(unauthorizedMessage);
				} else {
					response
						.json()
						.then((result) => resolve(result))
						.catch((error) => {
							if (!isNullOrUndefined(error) && error.toString().search(/abort/i) === -1) {
								paulBunyan.logError('Failed server request', error, true);
							}
							reject();
						});
				}
			})
			.catch((error) => {
				if (!isNullOrUndefined(error) && error.toString().search(/abort/i) === -1) {
					paulBunyan.logError('Failed server request', error, true);
				}
				reject();
			});
	});
}

export function updateOnServer() {
	// let fullUri = BASE_URI + uri;
	// return new Promise((resolve, reject) => {
	// 	talkToServer(REQUEST_TYPE.PUT, fullUri, resolve, reject, data);
	// });
	paulBunyan.logWarning('updateOnServer not implemented yet', undefined, true);
}

import PropTypes from 'prop-types';
import React, { useState } from 'react';

const NULL_ARRAY = [null];

const ExpandableListComponent = ({ list, listName }) => {
	const [hideList, setHideList] = useState(true);

	const renderList = () => {
		if (hideList) {
			return '';
		}

		return (
			<div className='col col-12'>
				{list.map((listItem, index) => {
					return (
						<div className='col' key={index}>
							{JSON.stringify(listItem)}
							<hr />
						</div>
					);
				})}
			</div>
		);
	};

	if (!list || JSON.stringify(list) === JSON.stringify(NULL_ARRAY)) {
		return <div>{`No ${listName}`}</div>;
	}

	return (
		<div className='row'>
			<div onClick={() => setHideList(!hideList)} className='col col-12'>
				{hideList ? '+' : '-'} {listName}
			</div>
			{renderList()}
		</div>
	);
};

ExpandableListComponent.propTypes = {
	list: PropTypes.array.isRequired,
	listName: PropTypes.string.isRequired
};

export default ExpandableListComponent;

import { getDomain, getEnvironment } from '@chris-crap-packages/browser-utilities';
import { Lumberjack } from '@chris-crap-packages/logger';
import { forceLocalHost, getBaseUri } from '@chris-crap-packages/utilities';

export const appName = 'tattle-tell';

export const logLevels = {
	error: 2,
	info: 0,
	warning: 1,
};

export let orgCode = '';
export const setOrgCode = (code) => {
	orgCode = code;
};

export const apiUri = forceLocalHost(getBaseUri(`${appName}-api`, getEnvironment(), getDomain()));
export const baseUri = forceLocalHost(getBaseUri(appName, getEnvironment(), getDomain()));

export const paulBunyan = new Lumberjack(apiUri, appName);
